const money = new Intl.NumberFormat('us-EN', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (amount: number): string => {
  return money.format(amount);
};

export default formatCurrency;
