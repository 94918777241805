/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface Context {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface DashboardViewed {
	''?: Record<string, any>
	context: Context
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context1 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License1 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface DraftOrderConfirmed {
	context: Context1
	/**
	 * Draft order that has been confirmed
	 */
	draftOrder: Record<string, any>
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License1
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context2 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License2 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface DraftOrderCreated {
	context: Context2
	draftOrder: Record<string, any>
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License2
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context3 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License3 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface DraftOrdersViewed {
	context: Context3
	/**
	 * Collection of draft orders orders on view
	 */
	draftOrders: any[]
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License3
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context4 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License4 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface PlacedOrderDownloaded {
	context: Context4
	/**
	 * Collection of orders that were downloaded.
	 */
	downloadedOrders: any[]
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License4
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context5 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License5 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface PlacedOrdersViewed {
	''?: Record<string, any>
	context: Context5
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License5
	/**
	 * Collection of placed orders on view
	 */
	placedOrders: any[]
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}
export interface Context6 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License6 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface ReUpParametersReset {
	''?: Record<string, any>
	context: Context6
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License6
}
export interface Context7 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License7 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface ReUpParametersSubmitted {
	/**
	 * Unique id assigned to track the usage of these parameter settings
	 */
	ParameterSettingsId: string
	context: Context7
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License7
}
export interface Context8 {
	/**
	 * This is the Organization Key for the GrowFlow customer that is firing this event.
	 */
	groupId: string
}
export interface License8 {
	/**
	 * Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
	 */
	licenseGroup?: string
	/**
	 * The name on the Cannabis license this event is firing for
	 */
	licenseName?: string
	/**
	 * The Cannabis License #
	 */
	licenseNumber?: string
	/**
	 * 2-digit Region or State abbreviation, all-caps
	 */
	regionAbbreviation?: string
}
export interface ReorderAssistantViewed {
	''?: Record<string, any>
	context: Context8
	/**
	 * User's primary email used with GrowFlow Applications
	 */
	email?: string
	license: License8
	/**
	 * Name of the GrowFlow product that this event is being sent from.
	 */
	product: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef Context
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef DashboardViewed
 * @property {Record<string, any>} [] -
 * @property {Context} context -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context1
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License1
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef DraftOrderConfirmed
 * @property {Context1} context -
 * @property {Record<string, any>} draftOrder - Draft order that has been confirmed
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License1} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context2
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License2
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef DraftOrderCreated
 * @property {Context2} context -
 * @property {Record<string, any>} draftOrder -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License2} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context3
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License3
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef DraftOrdersViewed
 * @property {Context3} context -
 * @property {any[]} draftOrders - Collection of draft orders orders on view
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License3} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context4
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License4
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef PlacedOrderDownloaded
 * @property {Context4} context -
 * @property {any[]} downloadedOrders - Collection of orders that were downloaded.
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License4} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context5
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License5
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef PlacedOrdersViewed
 * @property {Record<string, any>} [] -
 * @property {Context5} context -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License5} license -
 * @property {any[]} placedOrders - Collection of placed orders on view
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */
/**
 * @typedef Context6
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License6
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef ReUpParametersReset
 * @property {Record<string, any>} [] -
 * @property {Context6} context -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License6} license -
 */
/**
 * @typedef Context7
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License7
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef ReUpParametersSubmitted
 * @property {string} ParameterSettingsId - Unique id assigned to track the usage of these parameter settings
 * @property {Context7} context -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License7} license -
 */
/**
 * @typedef Context8
 * @property {string} groupId - This is the Organization Key for the GrowFlow customer that is firing this event.
 */
/**
 * @typedef License8
 * @property {string} [licenseGroup] - Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.
 * @property {string} [licenseName] - The name on the Cannabis license this event is firing for
 * @property {string} [licenseNumber] - The Cannabis License #
 * @property {string} [regionAbbreviation] - 2-digit Region or State abbreviation, all-caps
 */
/**
 * @typedef ReorderAssistantViewed
 * @property {Record<string, any>} [] -
 * @property {Context8} context -
 * @property {string} [email] - User's primary email used with GrowFlow Applications
 * @property {License8} license -
 * @property {string} product - Name of the GrowFlow product that this event is being sent from.
 */

/**
 * Records every view of the ReUp Dashboard
 *
 * @param {DashboardViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dashboardViewed(
	props: DashboardViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Records every view of the ReUp Dashboard',
		properties: {
			context: {},
			properties: {
				properties: {
					'': {
						description: '',
						id: '/properties/properties/properties/',
						properties: {},
						required: [],
						type: 'object',
					},
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering|ReUp)',
						type: 'string',
					},
				},
				required: ['context', 'license', 'product'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Dashboard Viewed',
		type: 'object',
	}
	const message = {
		event: 'Dashboard Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Dashboard Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every time a draft order is confirmed.
 *
 * @param {DraftOrderConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftOrderConfirmed(
	props: DraftOrderConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Records every time a draft order is confirmed. ',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					draftOrder: {
						description: 'Draft order that has been confirmed',
						id: '/properties/properties/properties/draftOrder',
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['draftOrder', 'context', 'license', 'product'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Draft Order Confirmed',
		type: 'object',
	}
	const message = {
		event: 'Draft Order Confirmed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Draft Order Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every time new draft orders are created
 *
 * @param {DraftOrderCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftOrderCreated(
	props: DraftOrderCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Records every time new draft orders are created',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					draftOrder: {
						description: '',
						id: '/properties/properties/properties/draftOrder',
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['context', 'license', 'product', 'draftOrder'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Draft Order Created',
		type: 'object',
	}
	const message = {
		event: 'Draft Order Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Draft Order Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every view of the ReUp Draft Orders including the draft order count
 *
 * @param {DraftOrdersViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftOrdersViewed(
	props: DraftOrdersViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Records every view of the ReUp Draft Orders including the draft order count',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					draftOrders: {
						description: 'Collection of draft orders orders on view',
						id: '/properties/properties/properties/draftOrders',
						type: 'array',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['context', 'license', 'product', 'draftOrders'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Draft Orders Viewed',
		type: 'object',
	}
	const message = {
		event: 'Draft Orders Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Draft Orders Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every time placed orders are downloaded.
 *
 * @param {PlacedOrderDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function placedOrderDownloaded(
	props: PlacedOrderDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Records every time placed orders are downloaded.',
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					downloadedOrders: {
						description: 'Collection of orders that were downloaded.',
						id: '/properties/properties/properties/downloadedOrders',
						type: 'array',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['downloadedOrders', 'context', 'license', 'product'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Placed Order Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Placed Order Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Placed Order Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every view of the ReUp Placed Orders including the placed order count
 *
 * @param {PlacedOrdersViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function placedOrdersViewed(
	props: PlacedOrdersViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Records every view of the ReUp Placed Orders including the placed order count',
		properties: {
			context: {},
			properties: {
				properties: {
					'': {
						description: '',
						id: '/properties/properties/properties/',
						type: 'object',
					},
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					placedOrders: {
						description: 'Collection of placed orders on view',
						id: '/properties/properties/properties/placedOrders',
						type: 'array',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['context', 'license', 'product', 'placedOrders'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Placed Orders Viewed',
		type: 'object',
	}
	const message = {
		event: 'Placed Orders Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Placed Orders Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every time that a ReUp user chooses to reset/edit Reordering Assistant parameters
 *
 * @param {ReUpParametersReset} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reUpParametersReset(
	props: ReUpParametersReset,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Records every time that a ReUp user chooses to reset/edit Reordering Assistant parameters',
		properties: {
			context: {},
			properties: {
				properties: {
					'': {
						description: '',
						id: '/properties/properties/properties/',
						type: 'object',
					},
					context: {
						description: '',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				required: ['context', 'license'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'ReUp Parameters Reset',
		type: 'object',
	}
	const message = {
		event: 'ReUp Parameters Reset',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ReUp Parameters Reset',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event records every time that a ReUp user modifies the parameters that drive recommendations in the Reorder Assistant
 *
 * @param {ReUpParametersSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reUpParametersSubmitted(
	props: ReUpParametersSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'This event records every time that a ReUp user modifies the parameters that drive recommendations in the Reorder Assistant',
		labels: {
			product: 'reup',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					ParameterSettingsId: {
						description:
							'Unique id assigned to track the usage of these parameter settings',
						id: '/properties/properties/properties/ParameterSettingsId',
						pattern:
							'/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i',
						type: 'string',
					},
					context: {
						description: '',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
				},
				required: ['ParameterSettingsId', 'context', 'license'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'ReUp Parameters Submitted',
		type: 'object',
	}
	const message = {
		event: 'ReUp Parameters Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'ReUp Parameters Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Records every view of the ReUp Reorder Assistant
 *
 * @param {ReorderAssistantViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reorderAssistantViewed(
	props?: ReorderAssistantViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Records every view of the ReUp Reorder Assistant',
		properties: {
			context: {},
			properties: {
				properties: {
					'': {
						description: '',
						id: '/properties/properties/properties/',
						type: 'object',
					},
					context: {
						description: '',
						id: '/properties/properties/properties/context',
						properties: {
							groupId: {
								description:
									'This is the Organization Key for the GrowFlow customer that is firing this event.',
								id:
									'/properties/properties/properties/context/properties/groupId',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: "User's primary email used with GrowFlow Applications",
						id: '/properties/properties/properties/email',
						pattern:
							'\t\n(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						type: 'string',
					},
					license: {
						description: '',
						id: '/properties/properties/properties/license',
						properties: {
							licenseGroup: {
								description:
									'Type of Cannabis License this event is related to; typical values: "Retail", "Producer", "Processor", "Microbusiness", etc.  Note that this is NOT the state-specific license-type.',
								id:
									'/properties/properties/properties/license/properties/licenseGroup',
								type: 'string',
							},
							licenseName: {
								description:
									'The name on the Cannabis license this event is firing for',
								id:
									'/properties/properties/properties/license/properties/licenseName',
								type: 'string',
							},
							licenseNumber: {
								description: 'The Cannabis License #',
								id:
									'/properties/properties/properties/license/properties/licenseNumber',
								type: 'string',
							},
							regionAbbreviation: {
								description: '2-digit Region or State abbreviation, all-caps',
								id:
									'/properties/properties/properties/license/properties/regionAbbreviation',
								pattern: '[A-Z]{2}',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					product: {
						description:
							'Name of the GrowFlow product that this event is being sent from.',
						id: '/properties/properties/properties/product',
						pattern:
							'(Wholesale|Retail|Insights|Live Marketplace|Harvest App|Retail Online Ordering)',
						type: 'string',
					},
				},
				required: ['context', 'license', 'product'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Reorder Assistant Viewed',
		type: 'object',
	}
	const message = {
		event: 'Reorder Assistant Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Reorder Assistant Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Records every view of the ReUp Dashboard
	 *
	 * @param {DashboardViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dashboardViewed,
	/**
	 * Records every time a draft order is confirmed.
	 *
	 * @param {DraftOrderConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftOrderConfirmed,
	/**
	 * Records every time new draft orders are created
	 *
	 * @param {DraftOrderCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftOrderCreated,
	/**
	 * Records every view of the ReUp Draft Orders including the draft order count
	 *
	 * @param {DraftOrdersViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftOrdersViewed,
	/**
	 * Records every time placed orders are downloaded.
	 *
	 * @param {PlacedOrderDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	placedOrderDownloaded,
	/**
	 * Records every view of the ReUp Placed Orders including the placed order count
	 *
	 * @param {PlacedOrdersViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	placedOrdersViewed,
	/**
	 * Records every time that a ReUp user chooses to reset/edit Reordering Assistant parameters
	 *
	 * @param {ReUpParametersReset} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reUpParametersReset,
	/**
	 * This event records every time that a ReUp user modifies the parameters that drive recommendations in the Reorder Assistant
	 *
	 * @param {ReUpParametersSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reUpParametersSubmitted,
	/**
	 * Records every view of the ReUp Reorder Assistant
	 *
	 * @param {ReorderAssistantViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reorderAssistantViewed,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/growflowdata/protocols/tracking-plans/rs_24pu0Osbfo5iybq5jqYFcf7Vlg7`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
